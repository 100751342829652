<template>
  <Layout tituloPagina="Solicitudes de instalación de servicios">
    <div class="row">
      <div class="col-sm-3 mb-3">
        <router-link
          :to="{ name: 'precioYMesesIncluidosParaSolicitudInstalacion' }"
          class="btn btn-secondary"
        >
          <i class="mdi mdi-cogs"></i>
          Precio y meses
        </router-link>
        &nbsp;&nbsp;
        <div class="form-check form-switch form-switch-lg" style="display: inline-block">
          <input
            :checked="todos"
            @change="palancaTodos()"
            class="form-check-input"
            type="checkbox"
            id="banderaTodos"
          />
          <label
            class="form-check-label"
            for="banderaTodos"
          >
            Todos
          </label>
        </div>
      </div>
      <div class="col-sm-7 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarSolicitudes()
          "
          :datoini="dato"
          placeholder="Dato de la solicitud"
        ></filtrador>
      </div>
      <div class="col-sm-2 col-3 text-right mb-3">
        <router-link
          :to="{ name: 'nuevaSolicitudInstalacion' }"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="contrato"
            v-model="columnas.contacto"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="contrato">Contacto</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="colonia"
            v-model="columnas.colonia"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="colonia">Colonia</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="correo"
            v-model="columnas.correo"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="correo">Correo</label>
        </div>
      </div>
      <div class="col-md-4 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nSolicitudesEnPag}} solicitudes en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nSolicitudesTotales}} solicitudes en total
        </span>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblSolicitudes" class="table table-hover table-nowrap">
            <thead>
              <tr>
                <th class="text-right" style="width: 60px">#ID</th>
                <th style=" min-width: 200px">Nombre</th>
                <th style=" width: 120px">Fecha registro</th>
                <th style=" width: 150px">Fecha programada</th>
                <th style=" width: 150px">Tiempo estimado</th>
                <th style=" width: 150px" v-show="columnas.contacto">Teléfono(s)</th>
                <th v-show="columnas.colonia">Colonia</th>
                <th style=" width: 150px" v-show="columnas.correo">Correo</th>
                <th style=" width: 90px">Estado</th>
                <th style=" width: 130px">Documento</th>
                <th style=" width: 240px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="solicitud in solicitudes" :key="solicitud.id">
                <td class="fw-semibold text-right">
                  <router-link
                    :to="{
                      name: 'edicionSolicitudInstalacion',
                      params: { id: solicitud.id }
                    }"
                  >
                    {{ solicitud.id }}
                  </router-link>
                </td>
                <td>
                  {{ solicitud.nombre }}
                  {{ solicitud.apellido_paterno }}
                  {{ solicitud.apellido_materno }}
                </td>
                <td class="fw-semibold">
                  {{ formatoDate(solicitud.fecha, 'DD/MM/YYYY', 'DD/MM/YYYY') }}
                </td>
                <td class="fw-semibold">
                  {{ formatoDate(solicitud.fecha_programada, 'DD/MM/YYYY - hh:mm A') }}
                </td>
                <td class="fw-semibold">
                  {{ convertidorSegundos_a_DHM(solicitud.tiempo_estimado) }}
                </td>
                <td v-show="columnas.contacto">
                  <button class="btn btn-outline-success bg-gradient btn-sm m-r-xs"
                    @click="mostrarMdlWhatsapp(solicitud.telefonos, solicitud)">
                    <i class="mdi mdi-notebook"></i>
                    Contactos
                  </button>
                </td>
                <td v-show="columnas.colonia">
                  {{solicitud.colonia}}
                </td>
                <td v-show="columnas.correo">
                  {{ solicitud.correo }}
                </td>
                <td>
                  <span
                    class="badge bg-warning bg-gradient font-size-13"
                    v-if="solicitud.id_estado == 1"
                  >
                    Pendiente
                  </span>

                  <span
                    class="badge bg-info bg-gradient font-size-13"
                    v-if="solicitud.id_estado == 2"
                  >
                    Proceso
                  </span>

                  <span
                    class="badge bg-primary bg-gradient font-size-13"
                    v-if="solicitud.id_estado == 3"
                  >
                    Completada
                  </span>

                  <span
                    class="badge bg-danger bg-gradient font-size-13"
                    v-if="solicitud.id_estado == 4"
                  >
                    Cancelada
                  </span>
                </td>
                <td>
                  <button 
                    class="btn btn-soft-dark btn-sm"
                    v-show="solicitud.id_estado != 4"
                    @click="imprimirSolicitud(solicitud)"
                  >
                    <i class="mdi mdi-printer"></i>
                    Imprimir
                  </button>
                </td>

                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'edicionSolicitudInstalacion',
                      params: { id: solicitud.id }
                    }"
                    class="btn btn-warning btn-sm"
                    v-if="
                      !(solicitud.id_estado == 3 || solicitud.id_estado == 4)
                    "
                  >
                    <i class="mdi mdi-square-edit-outline font-size-13"></i>
                    Editar
                  </router-link>
                  <router-link
                    :to="{
                      name: 'edicionSolicitudInstalacion',
                      params: { id: solicitud.id }
                    }"
                    class="btn btn-info btn-sm"
                    v-if="
                      solicitud.id_estado == 3 || solicitud.id_estado == 4
                    "
                  >
                    <i class="mdi mdi-eye"></i>
                    Mostrar
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaCancelarSolicitud(solicitud.id)"
                    v-if="
                      !(solicitud.id_estado == 3 || solicitud.id_estado == 4)
                    "
                  >
                    <i class="mdi mdi-trash-can-outline font-size-13"></i>
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarSolicitudes()
        "
      ></paginador>
    </div>
    <mdl-whatsapp ref="mdlWhatsapp"/>
    <br>
  </Layout>
</template>

<script>
/**
 * Listado de solicitudes de instalación
 */
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import SolicitudInstalacionSrv from '@/services/SolicitudInstalacionSrv.js'
import MdlWhatsapp from '@/views/clientes/MdlWhatsapp.vue'
import moment from 'moment'
import md5 from 'md5'

import Swal from 'sweetalert2'
import Layout from '@/views/layouts/main'
export default {
  name: 'ListadoSolicitudesInstalacion',
  components: { Layout, Paginador, Filtrador, MdlWhatsapp },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      baseUrl: window.location.origin, 
      dato: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoSolicitudesInstalacion.por_pagina') || 10),
      solicitudes: [],
      todos: false,
      total_solicitudes: 0,
      ultima_pagina: 0,
      nSolicitudesEnPag:0,
      nSolicitudesTotales:0,
      columnas: {
        contacto: true,
        colonia: false,
        correo: false
      },
    };
  },

  created: function() {
    var self = this

    // Carga de querys
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina)
      this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina)
      this.por_pagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.query.todos) this.todos = this.$route.query.todos == 'true' ? true : false

    // Ejecución de instrucciones
    self.inicio()
    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoSolicitudesInstalacion.columnas', JSON.stringify(self.columnas))
    },

    cargarPagina: function(nPag) {
      this.pagina = nPag
      this.refrescarSolicitudes()
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoSolicitudesInstalacion.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return dias+' dia '+horas+' hrs '+minutos+' min'
    },
    
    mostrarMdlWhatsapp(telefonos, solicitud) {
      let self = this,
          nombre =  (solicitud.nombre?solicitud.nombre:'') + ' ' +
                    (solicitud.apellido_paterno?solicitud.apellido_paterno:'') + ' ' +
                    (solicitud.apellido_materno?solicitud.apellido_materno:'')
      
      self.$refs.mdlWhatsapp.mostrar(telefonos, nombre)
    },

    formatoDate(mt, formato = 'DD/MM/YYYY', date = 'YYYY-MM-DD HH:mm:ss') {
      return moment(mt, date).format(formato)
    },

    inicio: function() {
      var self = this

      self.refrescarSolicitudes()
    },

    refrescarSolicitudes: function() {
      var self = this

      iu.spinner.mostrar('#tblSolicitudes')
      // Registro de los querys
      this.$router.replace({
        query: {
          dato: this.dato,
          pagina: this.pagina,
          por_pagina: this.por_pagina,
          todos: this.todos
        }
      }).catch(error => {})

      var params = {
        dato: self.dato,
        todos: self.todos,
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        cfg_solicitud: {
          id: true,
          nombre: true,
          apellido_paterno: true,
          apellido_materno: true,
          fecha: true,
          telefono: true,
          correo: true,
          id_estado: true
        }
      }

      SolicitudInstalacionSrv.solicitudesInstalacionJSON(params).then(response=>{
        localStorage.setItem('argusblack.listadoSolicitudesInstalacion.por_pagina', self.por_pagina)
        let res = response.data
        
        // Carga de los tickets
        self.solicitudes = res.solicitudes
        self.ultima_pagina = res.ultima_pagina
        self.total_solicitudes = res.total

        self.nSolicitudesEnPag = res.nSolicitudesEnPag
        self.nSolicitudesTotales = res.total

        if (self.pagina > self.ultima_pagina) {
          self.pagina = self.ultima_pagina
          self.refrescarSolicitudes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las solicitudes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tblSolicitudes')
      })
    },

    cancelarSolicitud: function(idSolicitud, motivo) {
      var self = this

      SolicitudInstalacionSrv.cancelar(idSolicitud, motivo).then(response => {
        iu.msg.success('Se canceló correctamente')
        self.refrescarSolicitudes()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puede cancelar la solicitud'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    imprimirSolicitud(solicitud) {
      let url = API+"/solicitudes-instalacion/"+solicitud.id+"/impresion/"+md5(solicitud.id.toString())
      window.open(url,'_blank')
    },

    palancaTodos: function() {
      var self = this

      if (self.todos == true) self.todos = false
      else self.todos = true

      self.refrescarSolicitudes()
    },
    
    preguntaCancelarSolicitud: function(idSolicitud) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de solicitud",
        html: '¿Desea cancelar la solicitud <strong>' + idSolicitud + '</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntaMotivoCancelacion(idSolicitud)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaMotivoCancelacion: function(idSolicitud) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Motivo de cancelación",
        html: '¿Por qué se canceló la instalación?',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          self.cancelarSolicitud(idSolicitud, text.value)
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tblSolicitudes')
  }
}
</script>

<style scoped>
</style>
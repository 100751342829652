import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/solicitudes-instalacion`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(solicitud) {
    return apiClient.post(`${solicitud.id}`, solicitud)
  },

  cancelar(idSolicitud, motivo) {
    return apiClient.post(`${idSolicitud}/cancelar`, {motivo:motivo})
  },

  guardar(solicitud) {
    return apiClient.post(`${API}/solicitudes-instalacion`, solicitud)
  },

  solicitudInstalacionJSON(idSolicitud) {
    return apiClient.get(`${idSolicitud}.json`)
  },

  solicitudesInstalacionJSON(params) {
    return apiClient.get(`${API}/solicitudes-instalacion.json`, { params: params })
  }
}

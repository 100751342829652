<template>
  <div class="modal fade" ref="mdlWhatsapp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Whatsapp de <strong>{{nombreCliente}}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Se muestran los numeros telefonicos registrados
          </p>

          <div class="table-responsive">
            <table class="table mb-0 align-middle table-nowrap">
              <thead>
                <tr>
                  <th class="text-center">Número</th>
                  <th class="text-center">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="telefono in telefonos" :key="telefono.id">
                  <td>
                    <input
                      type="text"
                      class="form-control text-center"
                      :value="(telefono.prefijo != '' && telefono.prefijo != null?'('+telefono.prefijo+') ':'')+telefono.numero"
                      readonly>
                  </td>
                  <td class="text-center">
                    <a 
                      :href="urlWhatsapp(telefono)"
                      target="_blank"
                      class="btn btn-success bg-gradient"
                      :title="`Numero: ${telefono.numero}`"
                      v-show="telefono.tipo == 'celular'"
                      >
                        <span class="d-block d-sm-none">
                          <i class="mdi mdi-whatsapp"></i>
                          whatsapp
                        </span>
                        <span class="d-none d-sm-block">
                          <i class="mdi mdi-whatsapp"></i>
                          Enviar whatsapp
                        </span>
                      </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlWhatsapp',

  data() {
    return {
      nombreCliente: '',
      telefonos: []
    }
  },

  methods: {
    mostrar(telefonos, nombreCliente) {
      let self = this

      self.telefonos = telefonos
      self.nombreCliente = nombreCliente

      const modal = new Modal(self.$refs.mdlWhatsapp)
      modal.show()
    },

    urlWhatsapp(telefono){
      let numero = `${telefono.prefijo} ${telefono.numero}`
      return `https://api.whatsapp.com/send/?phone=${this.whatsapp(numero)}&text=Hola ${this.nombreCliente}`
    },

    whatsapp(numero) {
      let num = numero.replace(/ /g, "").toString()
      num = num.replace(/-/g, "").toString()
      return num
    },
  },
}
</script>

<style scoped>

</style>